import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "AWS サービスを用いた今後のバッチ処理のお話〜開発本部・ TechLunch〜",
  "date": "2017-03-21T08:40:50.000Z",
  "slug": "entry/2017/03/21/174050",
  "tags": ["medley"],
  "hero": "./2017_03_21.png",
  "heroAlt": "aws"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」を開発している田中です。`}</p>
    <p>{`本日は、メドレー開発本部にて隔週で行われている勉強会（TechLunch）で、今後の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
      }}>{`バッチ処理`}</a>{`構成の 1 つとして活用できそうな`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/AWS"
      }}>{`AWS`}</a>{`サービスの紹介を行ったので、その一部を紹介したいと思います。`}</p>
    <h1>{`背景と勉強会の目的`}</h1>
    <p><strong parentName="p">{`メドレーでの`}<a parentName="strong" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
        }}>{`バッチ処理`}</a></strong></p>
    <p>{`メドレーの各プロダクトは環境として主に`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/"
      }}>{`AWS`}</a>{`を使用しており、いわゆる「`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
      }}>{`バッチ処理`}</a>{`」（時間起動でのデータ一括処理系）については、スケジューラとして使用している Jenkins からバッチ用 EC2`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%B9%A5%BF%A5%F3%A5%B9"
      }}>{`インスタンス`}</a>{`上の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
      }}>{`バッチ処理`}</a>{`をキックする、という構成が多いです。`}</p>
    <p>{`（短時間で完了し、ワークフロー自体シンプルなバッチについては、スケジューラに Lambda を利用する構成も増えてきました）`}</p>
    <p>{`その他、SQS を用いたキューイング処理、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Kinesis"
      }}>{`Kinesis`}</a>{`/Lambda を使用したログストリーミング処理など様々な構成で動いています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170321/20170321124505.png",
        "alt": null
      }}></img></p>
    <h1>{`勉強会の目的`}</h1>
    <p>{`現状、プロダクトを運用する上で`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
      }}>{`バッチ処理`}</a>{`で何か課題があるかと言えば特には無いのですが、下記を目的として勉強会を行いました。`}</p>
    <ul>
      <li parentName="ul">{`そもそも`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
        }}>{`バッチ処理`}</a>{`とは？という整理`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
        }}>{`バッチ処理`}</a>{`構成の新しい選択肢としてのインプット`}</li>
    </ul>
    <p>{`今後各プロダクトを跨ぐ共通基盤システムなどで`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
      }}>{`バッチ処理`}</a>{`が必要になった場合など、出来るだけ人の手間を減らしたい場合に備え、選択肢の 1 つとしてバッチ向けサービス（`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/AWS"
      }}>{`AWS`}</a>{` Batch、Step Functions）の紹介を行いました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170321/20170321124724.png",
        "alt": null
      }}></img></p>
    <p>{`また、広義の意味での`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
      }}>{`バッチ処理`}</a>{`/狭義の意味での`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
      }}>{`バッチ処理`}</a>{`という整理で、一括系処理系、ストリーミング/キューイングなどの（ほぼ）リアルタイム系の違いやよくある構成についての説明も行いました。`}</p>
    <h1>{`勉強会の内容`}</h1>
    <p>{`メドレーでは様々なバックグラウンドをもったエンジニアが集まっており、フロントエンドやネイティブは得意だけどサーバーサイド/インフラはまだ慣れていないエンジニア向けに、そもそも`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
      }}>{`バッチ処理`}</a>{`とは？という内容から始めました。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
        }}>{`バッチ処理`}</a>{`の概要、よくあるシステム構成`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
        }}>{`バッチ処理`}</a>{`設計時のポイント`}</li>
      <li parentName="ul">{`ここ最近のバッチ関連の流れ（リアルタイム化）`}</li>
      <li parentName="ul">{`各プロダクトのバッチ構成の例`}</li>
    </ul>
    <p>{`次に、今後使えそうな`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/AWS"
      }}>{`AWS`}</a>{`のサービスとして`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/AWS"
      }}>{`AWS`}</a>{` Batch と Step Functions の概要について説明しました。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/AWS"
        }}>{`AWS`}</a>{` Re:Invent 2016 で発表された`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/AWS"
        }}>{`AWS`}</a>{`の batch 処理系に使える新サービス`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/AWS"
        }}>{`AWS`}</a>{` Batch`}
        <ul parentName="li">
          <li parentName="ul">{`フルマネージド型の`}<a parentName="li" {...{
              "href": "https://d.hatena.ne.jp/keyword/%A5%D0%A5%C3%A5%C1%BD%E8%CD%FD"
            }}>{`バッチ処理`}</a>{`実`}<a parentName="li" {...{
              "href": "https://d.hatena.ne.jp/keyword/%B9%D4%B4%F0"
            }}>{`行基`}</a>{`盤`}</li>
          <li parentName="ul">{`必要なリソース（CPU、メモリ etc）を定義すれば、`}<a parentName="li" {...{
              "href": "https://d.hatena.ne.jp/keyword/AWS"
            }}>{`AWS`}</a>{`が必要に応じて ECS 上で実行（`}<a parentName="li" {...{
              "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%B9%A5%BF%A5%F3%A5%B9"
            }}>{`インスタンス`}</a>{`タイプ、分散用に台数確保）`}</li>
          <li parentName="ul">{`ジョブとして登録したアプリやコンテナイメージを実行`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Step Functions`}
        <ul parentName="li">
          <li parentName="ul">{`Lamba などの複数アプリをワークフローとして定義、実行（ビジュアル化）`}</li>
          <li parentName="ul">{`今までは Lambda to Lambda や、SQS を介すなど自分で考慮必要だったワークフロー（分岐、繰り返しなど）を`}<a parentName="li" {...{
              "href": "https://d.hatena.ne.jp/keyword/JSON"
            }}>{`JSON`}</a>{`で定義しサービス化`}</li>
        </ul>
      </li>
    </ul>
    <p>{`※ 発表資料は以下`}</p>
    <p>{`最後に、簡単にですが Step Functions を実際に試した内容と、個人的な感想を共有しました。`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Pros`}</p>
        <ul parentName="li">
          <li parentName="ul">{`既存の Lambda をそのまま使用できる`}</li>
          <li parentName="ul">{`フロー定義や実行結果がビジュアルで確認できる`}</li>
          <li parentName="ul">{`同じ Lambda を別々の State Machine で使えるので汎用性、再利用性が高まる`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cons`}</p>
        <ul parentName="li">
          <li parentName="ul">{`ワークフローが複雑になると、タスクの`}<a parentName="li" {...{
              "href": "https://d.hatena.ne.jp/keyword/JSON"
            }}>{`JSON`}</a>{`定義がけっこう手間（Step Functions に限った話ではないですが、、、）`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://d.hatena.ne.jp/keyword/%A5%C8%A5%E9%A5%F3%A5%B6%A5%AF%A5%B7%A5%E7%A5%F3"
            }}>{`トランザクション`}</a>{`的な管理、設計の勘所がけっこう必要になりそう`}</li>
        </ul>
      </li>
    </ul>
    <h1>{`まとめ`}</h1>
    <p>{`メドレー開発本部の技術勉強会（TechLunch）で発表した内容の一部を紹介しました。`}</p>
    <p>{`メドレーでは各技術の選択基準として、適材適所という考えを大事にしています。そのため、新技術/新サービスを使用すればいいと言う訳ではなく、今回の内容に関連して言えば、あえてベーシックにバッチ`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%B9%A5%BF%A5%F3%A5%B9"
      }}>{`インスタンス`}</a>{`を立てて、という構成も適材適所であれば問題ないと考えています。`}</p>
    <p>{`適材適所を効果的に行うためには、日々、新技術も情報として押さえ蓄積していく事が重要だと思うので、INPUT の一環として今後も「TechLunch」を更に活用していきたいと思います。`}</p>
    <h3>{`お知らせ`}</h3>
    <p>{`CTO 平山のブログが本日公開となりました。 記念すべき第一回目は、「医療 × インターネットの未来」がテーマ。 ぜひ読んでみてくださいね。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="医療×インターネットの未来 | メドレー平山の中央突破" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Ftoppa.medley.jp%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://toppa.medley.jp/">toppa.medley.jp</a></cite>
    <p>{`また、CTO 平山とのランチ会を定期開催予定です。 ご興味ある方はぜひエントリーください！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="ランチ企画第 3 弾！3/22、CTO 平山と語りたいエンジニア・デザイナー募集 by 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.wantedly.com%2Fprojects%2F92106" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.wantedly.com/projects/92106">www.wantedly.com</a></cite>
    <h3>{`求人情報`}</h3>
    <p>{`メドレーでは医療業界に存在する課題に IT を駆使して取り組んでいきたいデザイナー・エンジニアを募集中です。`}</p>
    <p>{`皆さまからのご応募お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      